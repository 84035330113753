.geomap .ol-zoom
  top: auto
  left: auto
  bottom: 25px
  right: 5px

.geomap .ol-zoomslider
  top: auto
  left: auto
  bottom: 61px
  right: 5px
  height: 25%
  background-color: rgba(210, 210, 210, 0.0)

.geomap .ol-touch .ol-zoomslider
  top: auto

.geomap .ol-zoomslider:hover
  background-color: rgba(210, 210, 210, 0.0)

.geomap .ol-zoomslider button
  width: 30px
  height: 10px
