.geomap .ol-control button
  height: 30px
  width: 30px
  font-size: 24px
  background-color: white
  opacity: 0.95
  color: black

.geomap .ol-control button:hover
  background-color: rgba(240, 240, 240, 0.95)

.geomap .ol-control button:focus
  background-color: rgba(240, 240, 240, 0.95)

.geomap .ol-control, .geomap .ol-scale-line
  font-family: Roboto, sans-serif !important
  background-color: rgba(210, 210, 210, 0.50)

.geomap .ol-control:hover
  background-color: rgba(210, 210, 210, 0.50)

.geomap .ol-rotate
  right 5px
  top 46px

.geomap .ol-attribution a
  color: #333333
  text-decoration: none
  font-family: Verdana, sans-serif
  font-size: 10px

.ol-attribution ul
  font-size .7rem
  line-height 1.375em

.geomap .ol-attribution.ol-uncollapsible
  background-color: rgba(210, 210, 210, 0.50)
