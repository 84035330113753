.geomap .ol-popup
  position: absolute
  background-color: white
  -webkit-filter: drop-shadow(0 1px 2px rgba(0,0,0,0.2))
  filter: drop-shadow(0 1px 2px rgba(0,0,0,0.2))
  padding: 15px
  border-radius: 10px
  border: 1px solid #cccccc
  left: -50px
  bottom: 45px
  min-width: 280px

.geomap .ol-popup:after, .ol-popup:before
  top: 100%
  border: solid transparent
  content: " "
  height: 0
  width: 0
  position: absolute
  pointer-events: none

.geomap .ol-popup:after
  border-top-color: white
  border-width: 10px
  left: 48px
  margin-left: -10px

.geomap .ol-popup:before
  border-top-color: #cccccc
  border-width: 11px
  left: 48px
  margin-left: -11px

.geomap .ol-popup-closer
  text-decoration: none
  position: absolute
  top: 2px
  right: 8px

.geomap .ol-popup-closer:after
  content: "✖"

.geomap .ol-popup .title
  font-weight bold
  margin-bottom 5px
